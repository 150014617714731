// libraries
import styled from '@emotion/styled'
import { css, keyframes, SerializedStyles, Theme } from '@emotion/react'
import Color from 'color'

// constants
import { MESSAGE_TYPES } from 'constants/message'

import type { ToastContainerProps } from 'components/common/Toast/type'
import type { MessageButtonProps, MessageIconProps, MessageType } from './type'

export const MESSAGE_STYLE_CONFIG = {
  borderLeftWidth: '8px',
  borderWidthOutline: '1px',
  borderRadius: '5px',
  boxShadow:
    '0 20px 25px -5px rgba(16, 24, 40, 0.1), 0px 8px 10px -6px rgba(16, 24, 40, 0.1)',
  animationDuration: '0.2s',
  animationDurationEnter: '0.2s',
  animationDurationLeave: '0.15s',
}

export const enterAnimation = keyframes`
  from { transform: scale(0.9); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
`

export const leaveAnimation = keyframes`
  from { transform: scale(1); opacity: 1; }
  to { transform: scale(0.9); opacity: 0; }
`

export const getMessageContainerStyles = (
  theme: Theme
): Record<MessageType, SerializedStyles> => ({
  [MESSAGE_TYPES.success]: css`
    border-left: ${MESSAGE_STYLE_CONFIG.borderLeftWidth} solid;
    border-color: ${theme.success};
  `,
  [MESSAGE_TYPES.error]: css`
    border-left: ${MESSAGE_STYLE_CONFIG.borderLeftWidth} solid;
    border-color: ${theme.danger};
  `,
  [MESSAGE_TYPES.info]: css`
    border-left: ${MESSAGE_STYLE_CONFIG.borderLeftWidth} solid;
    border-color: ${theme.info};
  `,
  [MESSAGE_TYPES.warning]: css`
    border-left: ${MESSAGE_STYLE_CONFIG.borderLeftWidth} solid;
    border-color: ${theme.warning};
  `,
  [MESSAGE_TYPES.primary]: css`
    border-left: ${MESSAGE_STYLE_CONFIG.borderLeftWidth} solid;
    border-color: ${theme.primary};
  `,
})

export const getMessageContainerOutlineStyles = (
  theme: Theme
): Record<MessageType, SerializedStyles> => ({
  [MESSAGE_TYPES.success]: css`
    background: ${theme['green-50']};
    border: ${MESSAGE_STYLE_CONFIG.borderWidthOutline} solid;
    border-color: ${theme['green-200']};
  `,
  [MESSAGE_TYPES.error]: css`
    background: ${theme['red-50']};
    border: ${MESSAGE_STYLE_CONFIG.borderWidthOutline} solid;
    border-color: ${theme['red-200']};
  `,
  [MESSAGE_TYPES.info]: css`
    background: ${theme['cyan-50']};
    border: ${MESSAGE_STYLE_CONFIG.borderWidthOutline} solid;
    border-color: ${theme['cyan-200']};
  `,
  [MESSAGE_TYPES.warning]: css`
    background: ${theme['yellow-50']};
    border: ${MESSAGE_STYLE_CONFIG.borderWidthOutline} solid;
    border-color: ${theme['yellow-200']};
  `,
  [MESSAGE_TYPES.primary]: css`
    background: ${theme['secondary-light-500']};
    border: ${MESSAGE_STYLE_CONFIG.borderWidthOutline} solid;
    border-color: ${Color(theme['secondary-light-500']).darken(0.1).string()};
  `,
})

export const getMessageIconColors = (
  theme: Theme
): Record<MessageType, string> => ({
  [MESSAGE_TYPES.success]: theme.success,
  [MESSAGE_TYPES.error]: theme.danger,
  [MESSAGE_TYPES.info]: theme.info,
  [MESSAGE_TYPES.warning]: theme.warning,
  [MESSAGE_TYPES.primary]: theme.primary,
})

export const getMessageButtonStyles = (
  theme: Theme
): Record<MessageType, SerializedStyles> => ({
  [MESSAGE_TYPES.success]: css`
    color: ${theme.success};
    &:hover {
      color: ${theme['green-700']};
    }
  `,
  [MESSAGE_TYPES.error]: css`
    color: ${theme['red-500']};
    &:hover {
      color: ${theme['red-600']};
    }
  `,
  [MESSAGE_TYPES.info]: css`
    color: ${theme['cyan-600']};
    &:hover {
      color: ${theme['cyan-700']};
    }
  `,
  [MESSAGE_TYPES.warning]: css`
    color: ${theme['yellow-700']};
    &:hover {
      color: ${theme['yellow-800']};
    }
  `,
  [MESSAGE_TYPES.primary]: css`
    color: ${theme.primary};
    &:hover {
      color: ${theme['secondary-600']};
    }
  `,
})

export const MessageContainer = styled.div<ToastContainerProps>`
  width: 100%;
  border-radius: ${MESSAGE_STYLE_CONFIG.borderRadius};
  position: relative;
  background: #fff;
  padding: 8px 0;

  animation: ${({ visible }) =>
    !visible &&
    css`
      ${leaveAnimation} ${MESSAGE_STYLE_CONFIG.animationDurationLeave} ease-in forwards
    `};

  ${({ type, outline, theme }) =>
    outline
      ? getMessageContainerOutlineStyles(theme)[type]
      : getMessageContainerStyles(theme)[type]};
`

export const MessageHeader = styled.div`
  display: flex;
  padding: 0 30px 0 20px;
`

export const MessageIcon = styled.div<MessageIconProps>`
  margin-right: 8px;
  display: flex;
  padding-top: 2px;
  color: ${({ type, theme }) => getMessageIconColors(theme)[type]};
`

export const MessageTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  word-break: break-word;
  line-height: 166%;
`

export const MessageDescription = styled.div`
  font-weight: 400;
  word-break: break-all;
  font-size: 14px;
  line-height: 166%;
  color: ${({ theme }) => theme['secondary-500']};
  padding: 6px 20px 14px;

  button {
    font-weight: 600;
    font-size: 12px;
    line-height: 166%;
    background: transparent;
    border: none;
    padding: 0;
    letter-spacing: 0.02em;
    color: ${({ theme }) => theme.primary};
    transition: color ${MESSAGE_STYLE_CONFIG.animationDuration} ease;

    &:hover {
      color: ${({ theme }) => theme['primary-800']};
    }
  }
};
`

export const MessageOptionalList = styled.div`
  display: flex;
  align-items: center;
`

export const MessageOptionalItem = styled.div`
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  line-height: 166%;
  color: ${({ theme }) => theme['secondary-500']};

  &:not(:last-child) {
    &::after {
      content: '•';
      margin: 0 4px;
    }
  }
`

export const MessageButtons = styled.div`
  border-top: 1px solid ${({ theme }) => theme['secondary-100']};
  display: flex;
  justify-content: end;
  padding: 10px 12px 2px;
`

export const MessageButton = styled.button<MessageButtonProps>`
  border: none;
  font-weight: 600;
  font-size: 10px;
  background: none;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${({ theme }) => theme['secondary-500']};
  transition: color ${MESSAGE_STYLE_CONFIG.animationDuration} ease;

  &:hover {
    color: ${({ theme }) => theme['secondary-600']};
  }

  &:not(:last-child) {
    margin-right: 15px;
  }

  &:last-child {
    ${({ typeToast, theme }) => getMessageButtonStyles(theme)[typeToast]}
  }
`

export const MessageClose = styled.button`
  position: absolute;
  right: 5px;
  top: 12px;
  display: flex;
  background: none;
  color: ${({ theme }) => theme['secondary-500']};
  border: none;
  transition: color ${MESSAGE_STYLE_CONFIG.animationDuration} ease;

  &:hover {
    color: ${({ theme }) => theme.primary};
  }
}; `
