import { coordEach } from '@turf/meta'
import clone from '@turf/clone'
import { featureCollection } from '@turf/helpers'
import booleanEqual from '@turf/boolean-equal'
import truncate from '@turf/truncate'
import _ from 'lodash'
import update from 'immutability-helper'
import geojsonhint from '@mapbox/geojsonhint'

// constants
import { SITE_FEATURE_TYPES } from 'constants/site'
import { GEOJSON_TYPES } from 'constants/map'

import type { Feature, Geometry, FeatureCollection } from 'geojson'
import type { Payload } from 'types/common'

// github.com/mapbox/mapbox-gl-draw/blob/69dd2b6e50d27264b1d79b608c944499790a73b3/src/api.js#L77-L79
export const validateGeojson = (geojson: Feature): { message: string }[] => {
  if (geojson?.geometry?.type === 'Point') {
    const [longitude, latitude] = geojson?.geometry?.coordinates || []
    if (
      latitude > 90 ||
      latitude < -90 ||
      longitude > 180 ||
      longitude < -180
    ) {
      return [
        {
          message: 'Coordinates not valid',
        },
      ]
    }
  }

  return geojsonhint
    .hint(geojson, { precisionWarning: false })
    .filter(e => e.level !== 'message')
}

export const hasInvalidGeometry = (feature: Feature): boolean => {
  return _.isEmpty(_.get(feature, 'geometry.coordinates'))
}

export const hasValidGeometry = (feature: Feature): boolean => {
  return !hasInvalidGeometry(feature)
}

export const getValidGeometry = (geometry: Geometry): Geometry => {
  return hasValidGeometry({ geometry })
    ? geometry
    : {
        coordinates: [0, 0],
        type: GEOJSON_TYPES.Point,
      }
}

export const transformGeojsonElevation = (
  geojson: Feature,
  elevation = 0
): Feature | undefined => {
  let clonedGeojsons
  try {
    clonedGeojsons = clone(geojson)
  } catch {
    return undefined
  }

  coordEach(clonedGeojsons, coord => {
    coord[2] = elevation
  })
  return clonedGeojsons
}

export const transformFeaturesElevation = (
  features = [],
  elevation?: number
): Feature[] => {
  const validFeatures = _.filter(features, hasValidGeometry)
  const invalidFeatures = _.reject(features, hasValidGeometry)
  const transformedData = transformGeojsonElevation(
    featureCollection(validFeatures),
    elevation
  )
  return [...transformedData.features, ...invalidFeatures]
}

export const getFeatureElevation = (
  feature: Feature,
  features: Feature[]
): number => {
  const defaultValue = 0
  if (_.isEmpty(feature)) return defaultValue

  const {
    properties: { parentId, floorHeight = defaultValue },
    featureType,
  } = feature
  if (featureType === SITE_FEATURE_TYPES.location) return 0
  if (featureType === SITE_FEATURE_TYPES.level) return floorHeight

  const parentFeature = _.find(features, { id: parentId })
  return getFeatureElevation(parentFeature, features)
}

export const updateFeatureCollectionFeatureByIndex = (
  feaCollection: FeatureCollection,
  index: number,
  feature: Feature
): FeatureCollection => {
  return update(feaCollection, {
    features: { [index]: { $set: feature } },
  })
}

export const updateFeatureByIndex = (
  features: Feature[],
  index: number,
  feature: Feature
): Feature[] => {
  return update(features, { [index]: { $set: feature } })
}

export const getFeatureGeometry = (feature: Feature): Geometry =>
  _.get(feature, 'geometry')

export const isFeatureGeometryEqual = (
  feature1: Feature,
  feature2: Feature
): boolean => {
  return booleanEqual(
    getFeatureGeometry(feature1),
    getFeatureGeometry(feature2)
  )
}

export const getGeometriesForFeatureCollection = (
  featureCollectionGeojson: FeatureCollection
): Geometry[] => {
  const { features } = featureCollectionGeojson
  return _.map(features, getFeatureGeometry)
}

export const isGeometryEqual = (
  geojson1: Feature | FeatureCollection | Geometry,
  geojson2: Feature | FeatureCollection | Geometry
): boolean => {
  if (!geojson1 || !geojson2) return false

  const geojson1Type = geojson1.type
  const geojson2Type = geojson2.type
  if (geojson1Type !== geojson2Type) return false

  if (geojson1Type === 'Feature')
    return isFeatureGeometryEqual(geojson1, geojson2)

  if (geojson1Type === 'FeatureCollection') {
    if (geojson1.features.length !== geojson2.features.length) return false

    return _.isEqual(
      getGeometriesForFeatureCollection(geojson1),
      getGeometriesForFeatureCollection(geojson2)
    )
  }

  if (geojson1Type === 'GeometryCollection') {
    if (geojson1.geometries.length !== geojson2.geometries.length) return false

    return _.isEqual(geojson1, geojson2)
  }

  return booleanEqual(geojson1, geojson2)
}

export const excludeElevation = (feature: Feature): Feature => {
  return truncate(feature, { coordinates: 2 })
}

/* Given a query in the form "lng, lat" or "lat, lng"
 * returns the matching geographic coordinate(s)
 * as search results in carmen geojson format,
 * https://github.com/mapbox/carmen/blob/master/carmen-geojson.md */
export const coordinatesGeocoder = (
  query: string
):
  | {
      center: number[]
      geometry: {
        type: string
        coordinates: number[]
      }
      // eslint-disable-next-line camelcase
      place_name: string
      // eslint-disable-next-line camelcase
      place_type: string[]
      properties: Payload
      type: string
    }[]
  | null => {
  // Match anything which looks like
  // decimal degrees coordinate pair.
  const matches = query.match(
    /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
  )
  if (!matches) {
    return null
  }

  const coordinateFeature = (lng: number, lat: number) => {
    return {
      center: [lng, lat],
      geometry: {
        type: 'Point',
        coordinates: [lng, lat],
      },
      place_name: `Lat: ${lat} Lng: ${lng}`,
      place_type: ['coordinate'],
      properties: {},
      type: 'Feature',
    }
  }

  const coord1 = Number(matches[1])
  const coord2 = Number(matches[2])
  const geocodes = []

  if (coord1 < -90 || coord1 > 90) {
    // must be lng, lat
    geocodes.push(coordinateFeature(coord1, coord2))
  }

  if (coord2 < -90 || coord2 > 90) {
    // must be lat, lng
    geocodes.push(coordinateFeature(coord2, coord1))
  }

  if (geocodes.length === 0) {
    // else could be either lng, lat or lat, lng
    geocodes.push(coordinateFeature(coord1, coord2))
    geocodes.push(coordinateFeature(coord2, coord1))
  }

  return geocodes
}
