// libraries
import _ from 'lodash'
import { point as turfPoint } from '@turf/helpers'

// constants
import { USER_ESSENTIAL_FIELDS } from 'services/api/user'
import {
  CORRELATE_EVENT_TYPES,
  DETECTION_TYPES,
  PROGRESS_MAPPING,
  RESOLUTION_MODE_MAPPING,
  WORK_ITEM_LABELS_MAPPING,
} from 'app/MissionControlMethaneSolution/constants/detection'

// utils
import { getFields, getInlineFragments, getQueryFields } from 'helpers/graphql'
import {
  getEntitiesQuery,
  getEntityGraphql,
  getEntityQuery,
  listEntitiesGraphql,
  mutateEntity,
  MutateEntity,
} from 'services/api/utils'
import { getDetectionTypeLabel } from 'app/MissionControlMethaneSolution/helpers/detection'

import type { Payload } from 'types/common'
import { EmissionEvent, Detection } from 'types/graphql'
import type { EmissionEventData } from 'app/MissionControlMethaneSolution/types/detection'
import type { QueryParams, SelectedFields } from 'types/services'
import { deserializeSite } from './detection'

export const DOMAIN = 'emissionEvent'
const queryDomain = `${DOMAIN}s`

export const EQUIPMENT_REFERENCE_FIELDS = {
  equipmentId: true,
  equipment: {
    id: true,
    name: true,
    description: true,
    components: {
      equipmentComponentName: true,
    },
  },
}

export const VENTING_EVENT_FIELDS_MAPPING = {
  VentingEvent: {
    ventingEventType: true,
    description: true,
    blowdown: true,
    startTime: true,
    endTime: true,
    ongoingStatus: true,
  },
}

const clueFields = {
  data: true,
  id: true,
  images: {
    label: true,
    url: true,
  },
  type: true,
}

export const SOURCE_ATTRIBUTION_FIELDS = {
  attributionExplanation: true,
  attributionType: true,
}

const DETECTION_FIELDS_MAPPING = {
  Detection: {
    status: true,
    screeningId: true,
    detectionType: true,
    detectionSource: true,
    sourceDescription: true,
    additionalData: true,
    purpose: true,
    media: {
      downloadUrl: true,
      mediaKey: true,
    },
    component: {
      equipmentComponentName: true,
    },
    equipmentExplanation: true,
    sourceAttribution: SOURCE_ATTRIBUTION_FIELDS,
    resolution: {
      correlatedDetection: {
        detection: {
          shortId: true,
        },
      },
      correlatedClue: {
        clue: _.pick(clueFields, ['id', 'data', 'type']),
      },
      resolutionMode: true,
      explanation: true,
    },
    clues: {
      clueGroups: {
        clues: {
          clue: clueFields,
        },
        type: true,
      },
    },
  },
}

export const EMISSION_EVENT_FIELDS = {
  id: true,
  detectedAt: true,
  shortId: true,
  acknowledged: true,
  emissionsRate: {
    value: true,
  },
  inputType: true,
  equipmentReference: EQUIPMENT_REFERENCE_FIELDS,
  priority: true,
  closed: true,
  sourceAttribution: SOURCE_ATTRIBUTION_FIELDS,
  siteReference: {
    site: {
      id: true,
      properties: {
        name: true,
      },
      outlier: true,
      outlierExplanation: true,
      deepDiveProperties: {
        underDeepDive: true,
      },
      observations: true,
    },
  },
  audit: {
    createdBy: {
      user: USER_ESSENTIAL_FIELDS,
    },
    updatedTime: true,
  },
  complianceDeadline: true,
  complianceSeverity: true,
  lastComplianceActionAt: true,
  correlatedEmissionEventCount: true,
  sourceLatitude: true,
  sourceLongitude: true,
  ...getInlineFragments({
    ...DETECTION_FIELDS_MAPPING,
    ...VENTING_EVENT_FIELDS_MAPPING,
  }),
}

export const getEmissionEventFields = getQueryFields(EMISSION_EVENT_FIELDS)

export const COMMON_SITE_FIELDS = getFields(
  ['id', 'properties'],
  'siteReference.site'
)

const COMMON_EMISSION_EVENT_FIELDS = [
  'id',
  'shortId',
  'detectedAt',
  'audit.updatedTime',
  'emissionsRate.value',
  'equipmentReference.equipmentId',
  'equipmentReference.equipment.description',
  'screeningId',
  'inputType',
  'detectionType',
  'priority',
  'siteReference.site.properties.name',
  'sourceAttribution',
  'sourceLongitude',
  'sourceLatitude',
  '__on[1]',
]

const COMMON_INBOX_EMISSION_EVENT_FIELDS = [
  'acknowledged',
  ...getFields(
    [
      '__typeName',
      'screeningId',
      'detectionType',
      'component.equipmentComponentName',
    ],
    '__on[0]'
  ),
  ...COMMON_EMISSION_EVENT_FIELDS,
  ...COMMON_SITE_FIELDS,
]

const COMMON_WIP_DETECTION_FIELDS = [
  'resolution',
  'complianceDeadline',
  'complianceSeverity',
  'lastComplianceActionAt',
  'correlatedEmissionEventCount',
  ...getFields(
    [
      '__typeName',
      'screeningId',
      'detectionType',
      'component.equipmentComponentName',
      'status',
    ],
    '__on[0]'
  ),
  ...COMMON_EMISSION_EVENT_FIELDS,
  ...COMMON_SITE_FIELDS,
]

const INBOX_DETECTION_VARIABLES = {
  first: 'Int',
  after: 'String',
  sortBy: 'EmissionEventSortBy',
  filter: 'InboxDetectionFilter',
}

const getEmissionEventsQuery = (
  queryName: string,
  variables: Payload<string> = INBOX_DETECTION_VARIABLES
) =>
  getEntitiesQuery<EmissionEvent>({
    queryDomain,
    queryName,
    variables,
    getFieldsFn: getEmissionEventFields,
  })

const INBOX_QUERY_NAME = 'inbox'

export const DETECTION_DEFAULT_OMIT_FIELDS = [
  '__on[0].clues',
  'siteReference.site.observations',
]

export const deserializeEmissionEvent = (
  emissionEvent: EmissionEvent
): EmissionEventData => {
  const {
    siteReference,
    equipmentReference,
    resolution,
    emissionsRate,
    complianceDeadline,
    complianceSeverity,
    audit,
    detectionType,
    component,
    priority,
    sourceLatitude,
    sourceLongitude,
    ventingEventType,
    status,
  } = emissionEvent || {}
  const { site } = siteReference ?? {}
  const { equipmentId, equipment } = equipmentReference ?? {}
  const { description: equipmentDescription, components } = equipment ?? {}
  const { explanation, resolutionMode, correlatedDetection, correlatedClue } =
    resolution ?? {}
  const { updatedTime } = audit ?? {}
  const { equipmentComponentName } = component || {}
  let emissionEventType = detectionType

  const correlatedDetectionShortId = _.get(
    correlatedDetection,
    'detection.shortId'
  )

  const correlatedToType = correlatedDetection
    ? CORRELATE_EVENT_TYPES.WIP
    : !_.isEmpty(correlatedClue)
    ? CORRELATE_EVENT_TYPES.CLUE
    : undefined

  if (ventingEventType) {
    emissionEventType = DETECTION_TYPES.DETECTION_TYPE_VENTING_FLARING_BLOWDOWN
  }

  return {
    ...emissionEvent,
    site: deserializeSite(site),
    emissionsRate: emissionsRate?.value,
    ...(explanation && { explanation }),
    ...(resolutionMode && {
      resolutionMode: _.get(RESOLUTION_MODE_MAPPING, resolutionMode),
    }),
    equipment: equipmentDescription ?? equipmentId,
    components,
    ...(equipmentComponentName && { equipmentComponentName }),
    compliance: { complianceSeverity, complianceDeadline },
    lastUpdatedTime: updatedTime,
    correlatedTo: {
      type: correlatedToType,
      correlatedDetectionShortId,
      correlatedClue,
    },
    type: getDetectionTypeLabel(emissionEventType),
    ...(_.isNumber(sourceLongitude) &&
      _.isNumber(sourceLatitude) && {
        geoJsonFeature: turfPoint([sourceLongitude, sourceLatitude], {
          priority,
        }),
      }),
    workItem: _.get(WORK_ITEM_LABELS_MAPPING, status),
    progress: _.get(PROGRESS_MAPPING, status),
    finding: null,
  }
}

const getEmissionEventByIdQuery = getEntityQuery({
  queryDomain,
  getFieldsFn: getEmissionEventFields,
})

export const getEmissionEventById = getEntityGraphql<EmissionEventData>({
  queryDomain,
  getQueryFn: getEmissionEventByIdQuery,
  queryDisplayName: 'GetEmissionEventById',
  postProcessFn: deserializeEmissionEvent,
})

const getEmissionEventByIdsQuery = getEntityQuery({
  queryDomain,
  queryName: 'byIds',
  variables: { ids: '[ID!]' },
  getFieldsFn: getEmissionEventFields,
})

/** Although the query returns a list, using 'getEntityGraphql' here to omit 'edges/node' */
export const getEmissionEventsByIds = getEntityGraphql<EmissionEventData[]>({
  queryDomain,
  queryName: 'byIds',
  getQueryFn: getEmissionEventByIdsQuery,
  queryDisplayName: 'GetEmissionEventByIds',
  postProcessFn: data => data?.map(deserializeEmissionEvent) || [],
})

const GET_EMISSION_EVENTS_COMMON_PROPS = {
  queryDomain,
  enableLoadMore: false,
  queryDisplayName: 'GetInboxInProgressDetections',
  defaultOmitFields: DETECTION_DEFAULT_OMIT_FIELDS,
  postProcessFn: deserializeEmissionEvent,
}

export const getInboxOpenEmissionEvents = listEntitiesGraphql<EmissionEvent>({
  ...GET_EMISSION_EVENTS_COMMON_PROPS,
  queryName: INBOX_QUERY_NAME,
  getQueryFn: getEmissionEventsQuery(INBOX_QUERY_NAME),
  queryDisplayName: 'GetInboxOpenEmissionEvents',
  defaultPickFields: COMMON_INBOX_EMISSION_EVENT_FIELDS,
})

const WIP_QUERY_NAME = 'workInProgress'

const WIP_DETECTION_VARIABLES = {
  ...INBOX_DETECTION_VARIABLES,
  filter: 'WipDetectionFilter',
}

export const getWIPEmissionEvents = listEntitiesGraphql<Detection>({
  ...GET_EMISSION_EVENTS_COMMON_PROPS,
  queryName: WIP_QUERY_NAME,
  getQueryFn: getEmissionEventsQuery(WIP_QUERY_NAME, WIP_DETECTION_VARIABLES),
  queryDisplayName: 'GetWIPEmissionEvents',
  defaultPickFields: COMMON_WIP_DETECTION_FIELDS,
})

const mutateEmissionEvent =
  ({
    pickFields,
    omitFields,
    ...restProps
  }: Omit<MutateEntity, 'queryDomain'> & SelectedFields) =>
  ({
    id,
    fieldsWithArguments,
    ...rest
  }: { id?: string; fieldsWithArguments?: QueryParams } & QueryParams) =>
    mutateEntity<EmissionEventData>({
      queryDomain,
      responseFields: {
        [DOMAIN]: getEmissionEventFields({ pickFields, omitFields }),
      },
      responsePath: [DOMAIN],
      withIdentifier: false,
      ignoreError: true,
      postProcessFn: deserializeEmissionEvent,
      ...restProps,
    })(id, rest, fieldsWithArguments) as Promise<{
      data: EmissionEventData
      error?: string
    }>

const ACKNOWLEDGE_EMISSION_EVENT_PAYLOAD = {
  fnName: 'acknowledgeEmissionEvent',
  variableFormat: 'AcknowledgeEmissionEventInput!',
  withIdentifier: true,
  identifier: 'emissionEventId',
  omitFields: [
    '__on[0].resolution',
    '__on[0].status',
    'lastComplianceActionAt',
    'complianceSeverity',
    'complianceDeadline',
    'correlatedEmissionEventCount',
  ],
}

export const acknowledgeEmissionEvent = mutateEmissionEvent({
  ...ACKNOWLEDGE_EMISSION_EVENT_PAYLOAD,
})

export const attributeEmissionEventToEquipment = mutateEmissionEvent({
  fnName: 'attributeEmissionEventToEquipment',
  variableFormat: 'AttributeEmissionEventToEquipmentInput!',
  pickFields: [
    'equipmentReference',
    ...getFields(
      ['__typeName', 'component', 'equipmentExplanation', 'sourceAttribution'],
      '__on[0]'
    ),
  ],
})

export const attributeEmissionEventToSite = mutateEmissionEvent({
  fnName: 'attributeEmissionEventToSite',
  variableFormat: 'AttributeEmissionEventToSiteInput!',
  pickFields: [
    'sourceAttribution',
    ...getFields(['__typeName', 'sourceAttribution'], '__on[0]'),
  ],
})

export const requestVfbReport = mutateEmissionEvent({
  fnName: 'markDetectionAsVfbReportRequested',
  responseFields: {
    detection: {
      status: true,
    },
  },
  variableFormat: 'MarkDetectionAsVfbReportRequestedInput!',
})
